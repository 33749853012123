import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable, formatToDecimalPlaces } from '@helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { DATE_TIME_FORMAT } from '@constants';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT } from '@constants/tooltips';
import { useTimezone } from '@hooks/useTimezone';

function VoyageSummaryTable({ loading, voyagePortCalls }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);
  const { getTimeZoneDisplay } = useTimezone();
  const columns = [
    {
      name: 'portName',
      label: 'Port Name',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'arrivalDate',
      label: 'Time of Arrival',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex) => {
          const date = voyagePortCalls[dataIndex].arrivalDate;
          return getTimeZoneDisplay(date);
        }
      }
    },
    {
      name: 'departureDate',
      label: 'Time of Departure',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex) => {
          const date = voyagePortCalls[dataIndex].departureDate;
          return getTimeZoneDisplay(date);
        }
      }
    },
    {
      name: 'percentArrivalDraught',
      label: 'Percent Arrival Draught',
      options: {
        display: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'arrivalDraught',
      label: 'Draught at Arrival(m)',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const referenceDraught = rowIndex.rowData[7];
          const referenceDraughtText = formatToDecimalPlaces(referenceDraught, 1);
          const percentageValue = rowIndex.rowData[3];
          const percentage = formatToDecimalPlaces(percentageValue, 1);
          const arrivalDraught = formatToDecimalPlaces(dataIndex, 1);

          return `${arrivalDraught}/${referenceDraughtText} (${percentage}%)`;
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'percentDepartureDraught',
      label: 'percent Departure Draught',
      options: {
        display: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'departureDraught',
      label: 'Draught at Departure(m)',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT,
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex, rowIndex) => {
          const referenceDraught = rowIndex.rowData[7];
          const referenceDraughtText = formatToDecimalPlaces(referenceDraught, 1);
          const percentageValue = rowIndex.rowData[5];
          const percentage = formatToDecimalPlaces(percentageValue, 1);
          const departureDraught = formatToDecimalPlaces(dataIndex, 1);

          return `${departureDraught}/${referenceDraughtText} (${percentage}%)`;
        },
        filterType: 'multiselect'
      }
    },
    {
      name: 'imoDraught',
      label: 'imoDraught',
      options: {
        display: false,
        filter: false,
        download: false
      }
    },
    {
      name: 'distanceKm',
      label: 'Distance Computed(km)',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex) => {
          const value = voyagePortCalls[dataIndex]?.distanceKm;
          return formatNumberReadable(formatToDecimalPlaces(value, 0));
        }
      }
    },
    {
      name: 'cargoWeightTn',
      label: 'Estimated Cargo Weight(t)',
      options: {
        filter: false,
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex) => {
          const value = voyagePortCalls[dataIndex]?.cargoWeightTn;
          return formatNumberReadable(formatToDecimalPlaces(value, 0));
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: true,
    search: true,
    download: checkUserHasAccess(user, true),
    onDownload: () => {
      const doc = new jsPDF();
      const tableColumn = columns.map((col) => col.label);
      const tableRows = voyagePortCalls.map((call) => [
        call.portName,
        moment(call.arrivalDate).format(DATE_TIME_FORMAT),
        moment(call.departureDate).format(DATE_TIME_FORMAT),
        formatToDecimalPlaces(call.percentArrivalDraught, 1),
        `${formatToDecimalPlaces(call.arrivalDraught, 1)}/${formatToDecimalPlaces(
          call.imoDraught,
          1
        )} (${formatToDecimalPlaces(call.percentArrivalDraught, 1)}%)`,
        formatToDecimalPlaces(call.percentDepartureDraught, 1),
        `${formatToDecimalPlaces(call.departureDraught, 1)}/${formatToDecimalPlaces(
          call.imoDraught,
          1
        )} (${formatToDecimalPlaces(call.percentDepartureDraught, 1)}%)`,
        formatToDecimalPlaces(call.imoDraught, 1),
        formatNumberReadable(formatToDecimalPlaces(call.distanceKm, 0)),
        formatNumberReadable(formatToDecimalPlaces(call.cargoWeightTn, 0))
      ]);

      doc.autoTable({
        head: [tableColumn],
        body: tableRows
      });

      doc.save('voyage-summary.pdf');
      return false; // Prevent the default CSV download
    },
    setTableProps: () => {
      return {
        id: 'voyage-summary-table'
      };
    },
    enableNestedDataAccess: '.',
    print: false,
    viewColumns: false,
    pagination: false,
    tableBodyMaxHeight: '15rem',
    toolbar: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0
  };

  return (
    <Paper id={'voyage-summary-table-body'}>
      <Table
        skeletonRows={4}
        loading={loading}
        mode={theme.mode}
        title={
          <Typography id={'voyage-summary-table-header'} variant={'h6'}>
            Summary of voyage
          </Typography>
        }
        data={voyagePortCalls ?? []}
        columns={columns}
        options={options}
        id={'voyage-summary-table'}
        customStyle={{
          MUIDataTableToolbar: {
            styleOverrides: {
              root: {
                padding: '0 16px'
              }
            }
          }
        }}
      />
    </Paper>
  );
}

VoyageSummaryTable.propTypes = {
  loading: PropTypes.bool,
  voyagePortCalls: PropTypes.array
};

VoyageSummaryTable.defaultProps = {
  loading: false,
  voyagePortCalls: []
};

export default VoyageSummaryTable;
