// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapbox-popup-priority {
  z-index: 2;
}

.mapbox-popup-text {
  color: #000000;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/route-map.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;AACjB","sourcesContent":[".mapbox-popup-priority {\n  z-index: 2;\n}\n\n.mapbox-popup-text {\n  color: #000000;\n  font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
