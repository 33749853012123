import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Table, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

function OwnerOrOperatorHistory({ loading, historyData, ownerOrOperator }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const columns = [
    {
      name: 'startDate',
      label: 'From',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex) => {
          return dataIndex !== null ? moment(dataIndex).format('DD/MM/yyyy') : '';
        }
      }
    },
    {
      name: 'endDate',
      label: 'To',
      options: {
        sort: true,
        sortThirdClickReset: true,
        customBodyRender: (dataIndex) => {
          return dataIndex !== null ? moment(dataIndex).format('DD/MM/yyyy') : 'Current';
        }
      }
    }
  ];

  if (ownerOrOperator === 'Operator') {
    columns.unshift(
      {
        name: 'companyName',
        label: 'Operator',
        options: {
          sort: true,
          display: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} name={dataIndex} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'companyId',
        options: { display: false, viewColumns: false, filter: false }
      }
    );
  } else if (ownerOrOperator === 'Owner') {
    columns.unshift(
      {
        name: 'companyName',
        label: 'Owner',
        options: {
          sort: true,
          display: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'owner'} name={dataIndex} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'companyId',
        options: { display: false, viewColumns: false, filter: false }
      }
    );
  }

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    toolbar: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `vessel-${ownerOrOperator}-history.csv`,
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true, // Export only displayed columns
        useDisplayedRowsOnly: true // Export only displayed rows
      },
      filterModel: {
        items: columns
          .filter((column) => !column.options?.display === false)
          .map((column) => ({ column: column.name }))
      }
    },
    elevation: 0,
    print: false,
    viewColumns: false,
    pagination: false,
    download: checkUserHasAccess(user, true),
    sortOrder: {
      name: 'startDate',
      direction: 'desc'
    }
  };
  const assetName = ownerOrOperator?.toLowerCase();

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Typography variant={'h6'} id={`${assetName}-table-title`}>
            {ownerOrOperator} History
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ pl: 2, pr: 2 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} id={`${assetName}-history-table`}>
          <Table
            customStyle={{
              MUIDataTableFilterList: { display: 'none' },
              MUIDataTableToolbar: {
                styleOverrides: {
                  root: {
                    minHeight: 0
                  }
                }
              }
            }}
            loading={loading}
            mode={theme.mode}
            data={historyData?.length ? historyData : []}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

OwnerOrOperatorHistory.propTypes = {
  historyData: PropTypes.arrayOf(PropTypes.object),
  ownerOrOperator: PropTypes.oneOf(['Owner', 'Operator']).isRequired,
  loading: PropTypes.bool
};

OwnerOrOperatorHistory.defaultProps = {
  historyData: [],
  loading: false
};

export default OwnerOrOperatorHistory;
