export const VoyageSummaryMapOptions = {
  PORT: 'port',
  VOYAGE_INFO: 'voyage-info',
  SHORTEST_ROUTE: 'shortest-route',
  REGION_AREA: 'region-area',
  SPEED: 'speed',
  DRAUGHT: 'draught',
  DRAUGHT_GRADE: 'draught-grade'
};

export const MapViewOptions = [
  { id: 1, stateKey: 'port', label: 'Port', value: VoyageSummaryMapOptions.PORT },
  {
    id: 2,
    stateKey: 'voyageInfo',
    label: 'Voyage info',
    value: VoyageSummaryMapOptions.VOYAGE_INFO
  },
  {
    id: 3,
    label: 'Shortest route',
    stateKey: 'shortestPath',
    value: VoyageSummaryMapOptions.SHORTEST_ROUTE,
    description:
      'Predefined shortest routes by Esgian between ports visited in the voyage. Used for emissions reporting.'
  },
  // { id: 4, label: 'Commercial regions', value: VoyageSummaryMapOptions.REGION_AREA },
  { id: 5, stateKey: 'speed', label: 'Speed', value: VoyageSummaryMapOptions.SPEED },
  { id: 6, stateKey: 'draught', label: 'Draught', value: VoyageSummaryMapOptions.DRAUGHT },
  {
    id: 7,
    stateKey: 'draughtGrade',
    label: 'Draught Percentage',
    value: VoyageSummaryMapOptions.DRAUGHT_GRADE
  }
];

export const MapViewOptionsStateKeys = {
  PORT: 'port',
  VOYAGE_INFO: 'voyageInfo',
  SHORTEST_ROUTE: 'shortestPath',
  REGION_AREA: 'regionArea',
  SPEED: 'speed',
  DRAUGHT: 'draught',
  DRAUGHT_GRADE: 'draughtGrade'
};

export const VoyageSummaryFilterKeys = {
  SELECTED_MAP_OPTIONS: 'selectedMapOptions',
  SELECTED_DATE: 'selectedDate',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  FROM_PORT: 'fromPort',
  TO_PORT: 'toPort',
  PERIOD_TYPE: 'periodType',

  FROM_PORT_ID: 'fromPortId',
  TO_PORT_ID: 'toPortId',
  DEPARTURE_DATE: 'departureDate',
  ARRIVAL_DATE: 'arrivalDate'
};

export const VoyageSummaryPeriodTypes = [
  { label: 'By Calendar', value: 'calendar' },
  { label: 'By Port', value: 'port' }
];
