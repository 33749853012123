import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Typography } from '@esgian/esgianui';
import ValueDisplay from '@components/ValueDisplay/ValueDisplay';
import { ProfileLink } from '@components/Links';
import { formatNumberReadable } from '@helpers';
import moment from 'moment';

function VesselOverview({
  showTitle,
  vesselsDataByFilters,
  loading,
  isRoRo,
  title,
  width,
  isShowInSingleColumn,
  backgroundColor
}) {
  const {
    imo,
    buildYear,
    deadweight,
    grossTonnage,
    maxLiftingCapacity,
    classification = {},
    flag = {},
    vesselCapacityDetail = {}
  } = vesselsDataByFilters?.[0]?.vessel || {};
  const vesselClass = classification?.class || '-';
  const flagState = flag?.name || '-';
  const carCapacity = vesselCapacityDetail?.carCapacity || '-';
  const numCranes = vesselsDataByFilters?.[0]?.vessel?.vesselCraneLinks?.length; //Count number of cranes
  const operatorInformation = vesselsDataByFilters?.[0]?.operatorInformation || [];
  const latestOperatorInfo = operatorInformation.reduce((latest, current) => {
    return moment(current.startDate).isAfter(moment(latest.startDate)) ? current : latest;
  }, operatorInformation[0] || {});

  const ownershipInformation = vesselsDataByFilters?.[0]?.ownershipInformation || [];
  const latestOwnershipInfo = ownershipInformation.reduce((latest, current) => {
    return moment(current.startDate).isAfter(moment(latest.startDate)) ? current : latest;
  }, ownershipInformation[0] || {});

  const vesselNameHistory = vesselsDataByFilters?.[0]?.vessel?.vesselNameHistory || [];
  const formerNames = vesselNameHistory
    .filter((vessel) => vessel.current === false)
    .map(({ name }) => name)
    .join(', ')
    .toUpperCase(); // Filter out the previous Names
  const { companyId: operatorId, companyName: operatorLongName } = latestOperatorInfo || {};
  const { companyId: ownerId, companyName: ownerLongName } = latestOwnershipInfo || {};
  const craneData = vesselsDataByFilters?.[0]?.vessel?.vesselCraneLinks || [];
  const craneCapacityData = (craneData) => {
    const capacities = craneData.map((item) => item.craneConfiguration.craneDetails.capacityMt);
    const capacityCount = capacities.reduce((acc, capacity) => {
      acc[capacity] = (acc[capacity] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(capacityCount)
      .map(([capacity, count]) => `${count} X ${capacity}mt`)
      .join(' + ');
  };

  const items = [
    { title: 'IMO', value: imo, className: 'vessel-details' },
    { title: 'Build Year', value: buildYear, className: 'vessel-details' },
    { title: 'DWT', value: `${formatNumberReadable(deadweight)} mt`, className: 'vessel-details' },
    {
      title: 'GT',
      value: grossTonnage ? `${formatNumberReadable(grossTonnage)}` : '-',
      className: 'vessel-details'
    },
    {
      title: isRoRo ? 'Capacity' : 'Max Lifting Capacity',
      value: isRoRo
        ? carCapacity !== null
          ? `${formatNumberReadable(carCapacity)} CEU`
          : '-'
        : maxLiftingCapacity !== null
        ? formatNumberReadable(maxLiftingCapacity)
        : '-',
      className: 'vessel-details'
    },

    {
      title: 'Operator',
      value: operatorLongName ? (
        <ProfileLink profile={'operator'} name={`${operatorLongName} `} id={operatorId} />
      ) : (
        '--'
      ),
      className: 'vessel-details'
    },
    isRoRo && {
      title: 'Owner',
      value: ownerLongName ? (
        <ProfileLink profile={'owner'} name={`${ownerLongName} `} id={ownerId} />
      ) : (
        '--'
      ),
      className: 'vessel-owner'
    },
    { title: 'Flag', value: flagState, className: 'vessel-details' },
    {
      title: 'Class',
      value: vesselClass,
      className: 'vessel-details'
    },
    !isRoRo && {
      title: 'No. Cranes',
      value: numCranes !== null ? numCranes : '-',
      className: 'vessel-details'
    },
    !isRoRo && {
      title: 'Gears in Overview Box',
      value: craneCapacityData(craneData) !== null ? craneCapacityData(craneData) : '-',
      className: 'vessel-class'
    },
    {
      title: 'Former Names',
      value: formerNames !== '' ? formerNames : '-',
      className: 'vessel-details'
    }
  ].filter(Boolean); // Filter out any false values (e.g., items not applicable for RoRo)

  return (
    <Paper sx={{ p: 2, minHeight: '40vh', width, backgroundColor }}>
      <Grid container spacing={2}>
        {showTitle && (
          <>
            <Grid item container spacing={2} xs={12}>
              <Grid item alignSelf={'center'}>
                <Typography variant={'h6'} id={'vessel-overview-title'}>
                  {title ?? 'Overview'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
        {isShowInSingleColumn ? (
          <Grid item xs={12}>
            <Grid container direction={'column'} spacing={1}>
              {items.map(({ title, value, className }) => (
                <ValueDisplay
                  key={title}
                  title={title}
                  value={value}
                  className={className}
                  loading={loading}
                />
              ))}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Grid container direction={'column'} spacing={1}>
                {items
                  .slice(0, Math.ceil(items.length / 1.5))
                  .map(({ title, value, className }) => (
                    <ValueDisplay
                      key={title}
                      title={title}
                      value={value}
                      className={className}
                      loading={loading}
                    />
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction={'column'} spacing={1}>
                {items.slice(Math.ceil(items.length / 1.5)).map(({ title, value, className }) => (
                  <ValueDisplay
                    key={title}
                    title={title}
                    value={value}
                    className={className}
                    loading={loading}
                  />
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
}

VesselOverview.propTypes = {
  vesselsDataByFilters: PropTypes.object,
  loading: PropTypes.bool,
  isRoRo: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showTitle: PropTypes.bool,
  isShowInSingleColumn: PropTypes.bool,
  backgroundColor: PropTypes.string
};

VesselOverview.defaultProps = {
  vesselsDataByFilters: null,
  loading: false,
  isRoRo: false,
  showTitle: true
};

export default VesselOverview;
