import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ArrowBackIcon,
  Button,
  Grid,
  IconButton,
  InfoOutlineIcon,
  MenuItem,
  Paper,
  Skeleton,
  Slide,
  Stack,
  Tab,
  Tabs,
  ToolTip,
  Typography,
  Menu,
  Box
} from '@esgian/esgianui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  getVoyageProfileFilters,
  VOYAGE_PROFILE_PARAMS
} from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';
import { getVoyageEmissionDetails } from '@api';

import { toast } from 'react-toastify';
import { EmissionAndEtsSection, VoyageSummarySection } from '@components/Sections';
import { VoyageSummaryFilterKeys } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryConstants';
import VoyageGroupsPopup from '@components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupsPopup';
import jsPDF from 'jspdf';
import {
  generatePortData,
  generateWaitingTime,
  generateActivityProfile,
  addNewPageIfNeeded,
  generateCargoGhgEmissionSummary,
  myCargoTocSummary,
  myCargoHocTable,
  overallVoyageTable,
  overallVoyageEmissionIntensityTable,
  costEstMyCargoTable,
  euEtsOverallVoyageTable,
  voyagesEstEtsCostTable,
  CO2EmissionTable,
  fuelConsumptionTable
} from '@helpers/pdfGeneratorServices';
import esgianLogo from '@assets/images/esgianLogo.png';
import reportIcon from '@assets/images/reportIcon.png';
import reportIconBlack from '@assets/images/reportIconBlack.png';
import { getThemeMode } from '@store/features';
import { getVoyagesDetails } from '@api/Voyage';
import { generateGhcEmissionReport } from './GhcEmissionReportGenerator';
import { useSegment } from '@hooks';
import { formatToDecimalPlaces } from '@helpers';

export const sectionShortNames = {
  1: 'Voyage Summary',
  2: 'Emission and ETS',
  3: 'Vessel Specification'
};

export const AisDataStatusColor = {
  'Very good': '#80DFEB',
  Good: '#4CAF50',
  Medium: 'white',
  Low: '#FF9800',
  'Very Low': '#EF5350'
};

export const AisDataDescription = {
  'Very Good': 'AIS Data quality is excellent',
  Good: 'AIS Data quality is upto requirements',
  Medium:
    'Some AIS data points corresponding to this voyage have not been received from the vessel, therefore corrections have been done on modelled emissions accordingly.',
  Low: 'AIS data corresponding to this voyage received from the vessel seems to be lower than usual, therefore corrections have been done on modelled emissions',
  'Very Low':
    'We have received very less AIS data for this voyage from the vessel. Correction factors have been applied to emissions to account for missing data. The estimations are prone to have errors due to lack of data.'
};

export const getAisStatus = (aisGrade) => {
  let status = 'Very Low';

  if (aisGrade > 95) {
    status = 'Very good';
  } else if (aisGrade > 85 && aisGrade <= 95) {
    status = 'Good';
  } else if (aisGrade > 80 && aisGrade <= 85) {
    status = 'Medium';
  } else if (aisGrade > 70 && aisGrade <= 80) {
    status = 'Low';
  }

  return status;
};

const CompletedVoyageProfilePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    mapOptions: { fullscreen }
  } = useSelector(getVoyageProfileFilters);
  const { isRoRo } = useSegment();

  const params = useParams();
  const section = parseInt(searchParams.get('section')) || 1;
  const [voyageData, setVoyageData] = useState(null);
  const navigate = useNavigate();
  const [loadedData, setLoadedData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [myCargoWeightForCustomizedCalculation, setMyCargoWeightForCustomizedCalculation] =
    useState(null);
  const [myEtsCostForCustomizedCalculation, setMyEtsCostForCustomizedCalculation] = useState(null);
  const isInitialLoad = useRef(true); // Ref to track initial load
  const { voyagePortCalls, voyageTransitDetails } = voyageData ?? {};
  const pdf = new jsPDF('landscape');
  const pdfHeader = pdf.addImage(esgianLogo, 'PNG', 230, 5, 56.5, 16);
  const themeMode = useSelector(getThemeMode);

  const handleSectionChange = (_, val) => {
    searchParams.set('section', val);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const {
    aisStatus = 0,
    aisColor,
    aisDataDescription
  } = useMemo(() => {
    if (!voyageData) return {};
    const {
      voyageTransitDetails: { aisDensity }
    } = voyageData;
    const aisStatus = getAisStatus(aisDensity ?? 0);
    return {
      aisStatus: aisStatus,
      aisColor: AisDataStatusColor[aisStatus],
      aisDataDescription: AisDataDescription[aisStatus]
    };
  }, [voyageData]);

  const hasEmission = voyageData?.voyageTransitDetails.activeEmission;

  useEffect(() => {
    if (searchParams.has('section')) {
      handleSectionChange(null, parseInt(searchParams.get('section')));
    } else {
      searchParams.set('section', section);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getVoyageProfileData = async () => {
      try {
        const filterParams = {
          imo: params.imo,
          fromPortId: parseInt(searchParams.get(VoyageSummaryFilterKeys.FROM_PORT_ID)),
          toPortId: parseInt(searchParams.get(VoyageSummaryFilterKeys.TO_PORT_ID)),
          startDate: searchParams.get(VoyageSummaryFilterKeys.DEPARTURE_DATE),
          endDate: searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE)
        };

        await getVoyagesDetails([filterParams], signal)
          .then((resp) => {
            setVoyageData(resp[0]);
          })
          .catch(() => {
            toast.error('Unable to fetch voyage data.', {
              position: 'top-right'
            });
          })
          .finally(() => {});
      } catch (error) {
        if (error.name === 'AbortError') {
          // console.log('Found abortError');
        } else {
          toast.error('Unable to fetch voyage data.', {
            position: 'top-right'
          });
        }
      }
    };

    let imo = params.imo;
    if (imo && Object.values(VOYAGE_PROFILE_PARAMS).every((param) => searchParams.has(param))) {
      getVoyageProfileData(imo);
    }
    return () => {
      controller.abort();
    };
  }, []);
  const { voyageOverview } = voyageData ?? {};

  const summary = {
    etsCost: formatToDecimalPlaces(loadedData?.emissionSummary?.etsCost, 2),
    co2e: formatToDecimalPlaces(loadedData?.emissionSummary?.co2eTn, 2),
    co2eIntensity: formatToDecimalPlaces(loadedData?.emissionSummary?.co2eIntensity, 3),
    co2: formatToDecimalPlaces(loadedData?.emissionSummary?.co2Tn, 2)
  };
  const fetchInitialEmissionAndEtsData = async () => {
    setIsLoadingData(true);
    const controller = new AbortController();
    const { signal } = controller;

    const fromPortId = +searchParams.get(VoyageSummaryFilterKeys.FROM_PORT_ID);
    const toPortId = +searchParams.get(VoyageSummaryFilterKeys.TO_PORT_ID);
    const departureDate = searchParams.get(VoyageSummaryFilterKeys.DEPARTURE_DATE);
    const arrivalDate = searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE);

    const filterParams = {
      imo: params.imo,
      fromPortId: fromPortId,
      toPortId: toPortId,
      startDate: departureDate,
      endDate: arrivalDate,
      myCargoWeight: null,
      myEtsCost: null
    };

    try {
      const [response] = await getVoyageEmissionDetails(filterParams, signal);
      setLoadedData(response);
      // Set initial values from the API response
      setMyCargoWeightForCustomizedCalculation(
        response.myCargoEuEtsAllowanceSummary.myCargoWeightTn
      );
      setMyEtsCostForCustomizedCalculation(response.myCargoEuEtsAllowanceSummary.etsCostPerCo2);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingData(false);
      isInitialLoad.current = false; // Mark the initial load as completed
    }
  };

  const fetchEmissionAndEtsData = async (myCargoWeight = null, myEtsCost = null) => {
    setIsLoadingData(true);
    const controller = new AbortController();
    const { signal } = controller;

    const fromPortId = +searchParams.get(VoyageSummaryFilterKeys.FROM_PORT_ID);
    const toPortId = +searchParams.get(VoyageSummaryFilterKeys.TO_PORT_ID);
    const departureDate = searchParams.get(VoyageSummaryFilterKeys.DEPARTURE_DATE);
    const arrivalDate = searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE);

    const filterParams = {
      imo: params.imo,
      fromPortId,
      toPortId,
      startDate: departureDate,
      endDate: arrivalDate,
      myCargoWeight,
      myEtsCost
    };

    try {
      const [response] = await getVoyageEmissionDetails(filterParams, signal);
      setLoadedData(response);
      // No need to set initial values here to avoid triggering useEffect
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const voyageOverviewReport = async () => {
    let yOffset = 5;
    pdfHeader;
    yOffset += 25;

    yOffset = generatePortData(pdf, voyagePortCalls.portCalls, yOffset, addNewPageIfNeeded);
    yOffset = generateActivityProfile(pdf, voyageTransitDetails, yOffset, addNewPageIfNeeded);
    yOffset = generateWaitingTime(pdf, voyagePortCalls, yOffset, addNewPageIfNeeded);

    pdf.save('Voyage-summary-report.pdf');
  };

  useEffect(() => {
    // Fetch initial data on mount
    fetchInitialEmissionAndEtsData();
  }, []);

  const ghgEmissionReport = async () => {
    generateGhcEmissionReport(loadedData, voyagePortCalls, isRoRo);
  };
  const euEtsCostEstimation = async () => {
    let yOffset = 5;
    pdfHeader;
    yOffset += 25;

    if (loadedData) {
      yOffset = costEstMyCargoTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = euEtsOverallVoyageTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset += 40;
      yOffset = voyagesEstEtsCostTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
    }

    pdf.save('EU ETS Cost Estimation.pdf');
  };

  const overallVoyageReport = async () => {
    let yOffset = 5;
    pdfHeader;
    yOffset += 25;

    yOffset = generatePortData(pdf, voyagePortCalls.portCalls, yOffset, addNewPageIfNeeded);
    yOffset = generateActivityProfile(pdf, voyageTransitDetails, yOffset, addNewPageIfNeeded);
    yOffset = generateWaitingTime(pdf, voyagePortCalls, yOffset, addNewPageIfNeeded);
    if (loadedData) {
      yOffset = generateCargoGhgEmissionSummary(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = myCargoTocSummary(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = myCargoHocTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = overallVoyageTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = overallVoyageEmissionIntensityTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = costEstMyCargoTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = euEtsOverallVoyageTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = voyagesEstEtsCostTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = CO2EmissionTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
      yOffset = fuelConsumptionTable(pdf, loadedData, yOffset, addNewPageIfNeeded);
    }

    pdf.save('Overall-voyage-report.pdf');
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReportOptionClick = (reportType) => {
    switch (reportType) {
      case 1:
        voyageOverviewReport();
        break;
      case 2:
        ghgEmissionReport();
        break;
      case 3:
        euEtsCostEstimation();
        break;
      case 4:
        overallVoyageReport();
        break;
      default:
        break;
    }
    handleClose();
  };

  return (
    <Grid container spacing={fullscreen ? 0 : 2} justifyContent={'stretch'}>
      {!fullscreen && (
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2}>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  background: '#80DFEB33',
                  borderRadius: '0.5rem',
                  height: 'fit-content'
                }}>
                <ArrowBackIcon fontSize={'small'} />
              </IconButton>
              <Stack spacing={1}>
                <Typography variant={'h5'}>
                  {!voyageData ? (
                    <Skeleton width={'20vw'} height={'1em'} variant={'rectangular'} />
                  ) : (
                    `Voyage from ${voyageOverview.departurePort} to ${voyageOverview.arrivalPort}`
                  )}
                </Typography>
                <Typography id={sectionShortNames?.[section]}>
                  {!voyageData ? (
                    <Skeleton width={'20vw'} />
                  ) : (
                    `From ${moment(voyageOverview.departureTime).format('YYYY-MM-DD')} to ${moment(
                      voyageOverview.arrivalTime
                    ).format('YYYY-MM-DD')}`
                  )}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <Button
                variant="text"
                sx={{
                  height: 'fit-content',
                  color: 'text.primary',
                  textTransform: 'none'
                }}
                startIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none">
                    <circle cx="4" cy="4" r="4" fill={aisColor} />
                  </svg>
                }
                endIcon={
                  <ToolTip title={aisDataDescription}>
                    <InfoOutlineIcon sx={{ fontSize: 16 }} />
                  </ToolTip>
                }>
                <Typography variant="body2">{`AIS: ${aisStatus}`}</Typography>
              </Button>
              <VoyageGroupsPopup mode="profile" />

              <Box>
                <Button
                  variant="text"
                  color={'primary'}
                  id={'ais-status'}
                  sx={{
                    height: 'fit-content',
                    size: '0.875rem',
                    textTransform: 'none'
                  }}
                  onClick={handleButtonClick}
                  startIcon={
                    themeMode ? (
                      <img src={reportIcon} alt={'reportIcon'} />
                    ) : (
                      <img src={reportIconBlack} alt={'reportIcon'} />
                    )
                  }>
                  <Typography variant="body2">REPORT</Typography>
                </Button>
                <Menu
                  id="va-report-download-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}>
                  <MenuItem onClick={() => handleReportOptionClick(1)}>Voyage Summary</MenuItem>
                  {loadedData && (
                    <>
                      <MenuItem onClick={() => handleReportOptionClick(2)}>
                        GHG Emission for scope 3 emission reporting
                      </MenuItem>
                      <MenuItem onClick={() => handleReportOptionClick(3)}>
                        EU ETS Cost Estimation
                      </MenuItem>
                    </>
                  )}
                  <MenuItem onClick={() => handleReportOptionClick(4)}>
                    Overall Voyage Report
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      )}
      {!fullscreen && (
        <Grid item xs={12}>
          <Paper>
            <Tabs onChange={handleSectionChange} value={section || 1}>
              <Tab
                value={1}
                id="voyage-summary-tab"
                label={'Voyage Summary'}
                sx={{ textTransform: 'none' }}
              />
              {hasEmission && loadedData && (
                <Tab
                  value={2}
                  id="emission-and-ets-tab"
                  label={'Emission and ETS'}
                  sx={{ textTransform: 'none' }}
                />
              )}
            </Tabs>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
          <div>
            <VoyageSummarySection
              voyageData={voyageData}
              onUpdateDepartureAndArrivalPort={({ selectedArrivalPort, selectedDeparturePort }) => {
                setSearchParams((prev) => {
                  prev.set(VoyageSummaryFilterKeys.FROM_PORT_ID, selectedDeparturePort?.portId);
                  prev.set(VoyageSummaryFilterKeys.TO_PORT_ID, selectedArrivalPort?.portId);
                  prev.set(
                    VoyageSummaryFilterKeys.DEPARTURE_DATE,
                    selectedDeparturePort?.departureDate
                  );
                  prev.set(VoyageSummaryFilterKeys.ARRIVAL_DATE, selectedArrivalPort?.arrivalDate);
                  return prev;
                });
                window.location.reload();
              }}
            />
          </div>
        </Slide>
        {loadedData && (
          <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
            <div>
              <EmissionAndEtsSection
                summary={summary}
                isLoadingData={isLoadingData}
                fetchEmissionAndEtsData={fetchEmissionAndEtsData}
                loadedData={loadedData}
                myCargoWeightForCustomizedCalculation={myCargoWeightForCustomizedCalculation}
                myEtsCostForCustomizedCalculation={myEtsCostForCustomizedCalculation}
                setMyCargoWeightForCustomizedCalculation={setMyCargoWeightForCustomizedCalculation}
                setMyEtsCostForCustomizedCalculation={setMyEtsCostForCustomizedCalculation}
                isInitialLoad={isInitialLoad}
              />
            </div>
          </Slide>
        )}
      </Grid>
    </Grid>
  );
};

export default CompletedVoyageProfilePage;
