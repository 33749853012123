export const scrollbarStyling = {
  '::-webkit-scrollbar': {
    width: '7px'
  },

  /* Track */
  '::-webkit-scrollbar-track': {
    borderRadius: '10px',
    background: ({ palette }) => palette.neutral.neutral01
  },

  /* Handle */
  ' ::-webkit-scrollbar-thumb': {
    background: (theme) => theme.palette.primary.main,
    borderRadius: '10px'
  }
};
