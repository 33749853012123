import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  fetchLookupCanals,
  fetchLookupLocations,
  fetchLookupRegions,
  fetchOwnerOperatorVesselHierarchy
} from '@api';
import { getUser } from '@store/features';
import { REGION_TYPES } from '@constants';
const importOrder = ['Region', 'Country', 'Port'];

const sortByLocation = importOrder.reduce((obj, item, index) => {
  return {
    ...obj,
    [item]: index
  };
}, {});

function sortRegions(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function locationSort(a, b) {
  return a?.name?.localeCompare(b?.name);
}

export const useStaticLookupQueries = () => {
  const queryClient = useQueryClient(); // Access queryClient instance
  const user = useSelector(getUser);

  const lookupRegionsQuery = useQuery({
    queryKey: ['lookupRegions', user],
    enabled: !!user,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchLookupRegions({ regionTypes: [REGION_TYPES.COMMERCIAL_REGIONS_SHIPS] }, signal)
        .then((resp) => resp.sort(sortRegions))
        .catch(() => []);
    }
  });

  const lookupLocationsQuery = useQuery({
    queryKey: ['lookupLocations', user],
    enabled: !!user,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchLookupLocations(signal)
        .then((resp) =>
          resp.sort(locationSort)?.sort((a, b) => sortByLocation[a.type] - sortByLocation[b.type])
        )
        .catch(() => []);
    }
  });
  const lookupCanalsQuery = useQuery({
    queryKey: ['lookupCanals', user],
    enabled: !!user,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchLookupCanals({ regionTypes: [REGION_TYPES.CANALS] }, signal)
        .then((resp) => resp)
        .catch(() => []);
    }
  });
  const lookupOwnerHierarchyQuery = useQuery({
    queryKey: ['ownerHierarchy', user],
    enabled: !!user,
    placeholderData: [],
    queryFn: ({ signal }) => {
      return fetchOwnerOperatorVesselHierarchy(signal)
        .then((resp) => {
          const { ownerOperatorAssetDetailsList = [] } = resp;
          return ownerOperatorAssetDetailsList;
        })
        .catch(() => []);
    }
  });

  const resetStaticLookupQueries = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['lookupRegions'] });
    await queryClient.invalidateQueries({ queryKey: ['lookupLocations'] });
    await queryClient.invalidateQueries({ queryKey: ['lookupCanals'] });
    await queryClient.invalidateQueries({ queryKey: ['ownerHierarchy'] });
  }, [queryClient]);

  return {
    lookupRegionsQuery,
    lookupLocationsQuery,
    lookupCanalsQuery,
    lookupOwnerHierarchyQuery,
    resetStaticLookupQueries
  };
};
