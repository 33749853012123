import moment from 'moment';
import { DATE_FORMAT } from '@constants';

export const checkUserHasAccess = (user) => {
  if (!user) return false;
  let {
    profile: { permissionList }
  } = user;

  // TODO check old logic, it broke for users with only access to RORO because the url did not have roro or mpp

  return !!permissionList?.find(
    (item) => item === 'SHIP_ANALYTICS_premiumAccount' || item === 'SHIP_ANALYTICS_trial'
  );
};

export const getDateList = (startDate, endDate) => {
  let dateStart = moment(startDate, DATE_FORMAT);
  let dateEnd = moment(endDate, DATE_FORMAT);
  if (!dateStart.isValid || !dateEnd.isValid) {
    return;
  }
  let interim = dateStart.clone();
  let categories = [];

  while (interim.isSameOrBefore(dateEnd)) {
    categories.push(interim.format('MMM yyyy'));
    interim.add(1, 'month');
  }
  return categories;
};
export const formatPeriodDisplay = (startDate, endDate) => {
  if (!startDate && !endDate) {
    return null;
  }
  let val = '';
  if (startDate) {
    val += moment(startDate, DATE_FORMAT).format('MMM yyyy');
  }
  val += ' - ';
  if (endDate) {
    val += moment(endDate, DATE_FORMAT).format('MMM yyyy');
  }
  return val;
};

export const cleanUpCurrentParams = (searchParams) => {
  let keys = [];
  searchParams.forEach((value, key) => {
    keys.push(key);
  });
  keys.forEach((key) => {
    searchParams.delete(key);
  });
  return searchParams;
};

export const checkMinMaxInputValid = (sizeMin, sizeMax) => {
  let error = null;
  if (sizeMax && sizeMin) {
    if (parseFloat(sizeMax) < parseFloat(sizeMin)) {
      error = 'Max can not be less than min';
    }
  }
  if (sizeMin) {
    if (parseFloat(sizeMin) < 0) {
      error = 'Min size must be greater than or equal 0';
    }
  }
  if (sizeMax) {
    if (parseFloat(sizeMax) < 0) {
      error = 'Max size must be greater than or equal 0';
    }
  }

  return error;
};
export const activityMapCheckValidFilters = (
  { sizeMin, sizeMax, minLiftingCapacity, maxLiftingCapacity },
  tempSelectedDate,
  inputErrors,
  setInputErrors
) => {
  let valid = true;
  let errors = { ...inputErrors };
  if (!moment(tempSelectedDate, DATE_FORMAT).isValid()) {
    valid = false;
    errors.date = 'Date invalid';
  }
  if (moment(tempSelectedDate, DATE_FORMAT).isAfter(moment())) {
    valid = false;
    errors.date = "Date can't be in the future";
  }
  if (valid) {
    errors.date = null;
  }
  errors.vesselSize = checkMinMaxInputValid(sizeMin, sizeMax);
  errors.minMaxLifting = checkMinMaxInputValid(minLiftingCapacity, maxLiftingCapacity);

  setInputErrors(errors);
  return valid;
};

export const formatNumberReadable = (number) => {
  if (
    number === null ||
    number === undefined ||
    number === Infinity ||
    number === 'Infinity' ||
    isNaN(number) ||
    number === '-'
  ) {
    return '-';
  }
  if (typeof number === 'number') {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else if (typeof number === 'string') {
    const parsedNumber = parseFloat(number);
    if (!isNaN(parsedNumber)) {
      return parsedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  }
  return '0';
};

const parseNumberToSignificant = (value) => {
  if (value > 100) {
    value = parseInt(value);
  } else if (value > 10) {
    value = parseFloat(value).toFixed(1);
  } else if (value > 1) {
    value = parseFloat(value).toFixed(2);
  } else {
    if (parseFloat(value) === 0) {
      return 0;
    }
    value = parseFloat(value).toFixed(3);
  }
  if (parseFloat(value) % 1 === 0) {
    value = parseInt(value);
  }
  return value;
};
const getDollarAmountReadable = (value) => {
  let tempVal = value;
  if (value === null) return '-';
  let unit = '';
  if (tempVal > 1000000) {
    unit = 'M';
    tempVal = tempVal / 1000000;
  } else if (tempVal > 1000) {
    unit = 'K';
    tempVal = tempVal / 1000;
  }
  tempVal = parseNumberToSignificant(tempVal);
  return `${tempVal}${unit}`;
};

export const formatAxisValue = (val) => {
  return getDollarAmountReadable(Number(val));
};

export const getPageType = () => {
  const path = window.location.pathname.split('/');
  return path[1];
};

export const getNumberWithSign = (num) => {
  return new Intl.NumberFormat('en-US', {
    signDisplay: 'exceptZero'
  }).format(num);
};

export const removeWhitespace = (string) => string.replace(/\s/g, '');
export const removeSpecialCharacters = (string) => string.replace(/[^\w\s]/g, '');

const countDecimalPlaces = (num) => {
  const strNum = num.toString();
  if (strNum.includes('.')) {
    return strNum.split('.')[1].length;
  }
  return 0;
};

export const formatToDecimalPlaces = (value, decimalPlaces, fallbackString = '-') => {
  if (
    value === null ||
    value === undefined ||
    value === fallbackString ||
    value === Infinity ||
    value === 'Infinity' ||
    isNaN(value)
  ) {
    return fallbackString;
  }

  const numValue = typeof value === 'string' ? parseFloat(value) : value;

  if (typeof numValue !== 'number') {
    return fallbackString;
  }

  if (decimalPlaces < 0) {
    decimalPlaces = 0;
  }

  const numberOfDecimalPlaces = countDecimalPlaces(numValue);
  if (numberOfDecimalPlaces <= decimalPlaces) {
    return numValue.toString();
  }

  return parseFloat(numValue.toFixed(decimalPlaces)).toString();
};

export const getChunkedArray = (arr, size) =>
  arr.length > size ? [arr.slice(0, size), ...getChunkedArray(arr.slice(size), size)] : [arr];
