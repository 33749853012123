export const APP_NAME = 'ships';

/** --- TIME---- */
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT_DAY_OF = 'MMMM Do YYYY, HH:mm:ss a';

export const UTC_TIMESTAMP = 'YYYY-MM-DDTHH:mm:ss[Z]';

/** --- VESSEL---- */
export const VESSEL_MAX_CAPACITY = 10000;
export const VESSEL_MAX_LIFTING_CAPACITY = 15000;
export const VESSEL_MAX_CAPACITY_DWT = 500000;
export const VESSEL_MIN_BUILD_YEAR = 1985;
export const VESSEL_MAX_TRANSIT_TIME = 100;
export const VESSEL_TOP_EMISSIONS = 15;
export const VESSEL_MAX_CAPACITY_CARS = 10000;

export const VESSEL_MAX_GROSS_TONNAGE = 500000;

/** --- OPERATOR---- */
export const OPERATOR_MAX_NUM_SERVICES = 100;
export const OPERATOR_MAX_FLEET_SIZE = 150;

export const OPERATOR_TOP_EMISSIONS = 10;

/** ---API DEFAULTS ---- */
export const DEFAULT_NUM_PORT_CALLS = 250;
export const MAX_NUM_PORT_CALLS = 99999;

export const OPERATOR_SELECT_TYPES = {
  AUTO: 'auto',
  DYNAMIC: 'dynamic',
  MANUAL: 'manual'
};

export const SEGMENT = {
  RoRo: { id: 1, value: 'SA_RO_RO' },
  MPP: { id: 2, value: 'SA_MPV' }
};

export const MAP_STYLE_DARK_MODE = 'mapbox://styles/gudbrandschistad/clj5c8kx0001a01qzbpxx5n80';
export const MAP_STYLE_LIGHT_MODE = 'mapbox://styles/gudbrandschistad/clj5ceqc7001k01qx7wt09lxd';
export const MAP_STYLE_SATELLITE_MODE =
  'mapbox://styles/gudbrandschistad/cljcn8f8k005201qu7h4c9og5';
export const MAP_STYLE_DEEP_DARK_MODE = 'mapbox://styles/mapbox/dark-v10';
export const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ3VkYnJhbmRzY2hpc3RhZCIsImEiOiJjbDZjYXkzdmIwcWx2M2JxbHppMjhkZHpqIn0.BUVI0Nirf9dkY6mDJ8hz9w';

export const OPERATOR_TYPE = {
  ALL: 0,
  SHORT_SEA: 1,
  DEEP_SEA: 2
};

export const COMMERCIAL_CATEGORY_TYPE = {
  ALL: 0,
  DEEP_SEA_CORE: 1,
  DEEP_SEA_EXT: 2,
  SHORT_SEA_EUR: 3,
  OTHER: 4
};

export const GEOGRAPHICAL_TYPES = {
  ALL: 0,
  PORT: 1,
  COUNTRY: 2,
  REGION: 3,
  CANAL: 4
};

export const REGION_TYPES = {
  COMMERCIAL_REGIONS_SHIPS: 1,
  WEATHER_REGIONS_SHIPS: 2,
  CANALS: 11
};

export const locationTypeIdMapping = {
  Port: 1,
  Country: 2,
  Region: 3
};

export const getLocationTypeId = (type) => {
  if (type === 'Port') return 1;
  if (type === 'Country') return 2;
  if (type === 'Region') return 3;
};
