import { useCallback, useEffect, useState } from 'react';
import screenfull from 'screenfull';

const useMapFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (!screenfull.isEnabled) {
      return;
    }
    const onFullscreenChange = () => {
      setIsFullscreen(screenfull.isFullscreen);
    };
    screenfull.on('change', onFullscreenChange);

    return () => {
      screenfull.off('change', onFullscreenChange);
    };
  }, []);

  const toggleFullscreen = useCallback((mapContainer) => {
    if (!mapContainer) return;
    if (screenfull.isEnabled) {
      screenfull.toggle(mapContainer);
    }
  }, []);

  return { isFullscreen, toggleFullscreen };
};

export default useMapFullscreen;
