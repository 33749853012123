import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Paper, ShipsIcon, InfoBox, Tabs, Tab, Slide } from '@esgian/esgianui';
import { useSearchParams } from 'react-router-dom';
import PortStatisticsSection from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortStatisticsSection/PortStatisticsSection';
import PortWaitingTimeSection from './SubSections/PortWaitingTimeSection';
import PortOverviewSection from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortOverviewSection';
import OperatorTypeSelect from '@components/Inputs/OperatorTypeSelect/OperatorTypeSelect';
import PortOperatorStatisticsSection from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortOperatorStatisticsSection';
import { GEOGRAPHICAL_TYPES, SEGMENT } from '@constants';
import { TextWithTooltipIcon } from '@components';
import { useSegment } from '@hooks';
import { setGeographicalType } from '@store/features';
import { useDispatch } from 'react-redux';
import { CommercialCategoryTypeSelect } from '@components/Inputs';

function PortProfileSection({
  setLastPortCalls,
  lastPortCalls,
  loading,
  portData,
  portStats,
  portCongestionData,
  operatorStats,
  selectedYear,
  upcomingPortCalls,
  maxDraughtLastPortCalls
}) {
  const [section, setSection] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const { segment } = useSegment();
  const dispatch = useDispatch();
  const isProd = process.env.CURRENT_ENV === 'prod';

  const handleSectionChange = useCallback(
    (_, val) => {
      if (val === 2 && !portStats) {
        return;
      }
      searchParams.set('section', val);
      setSearchParams(searchParams);
      setSection(val);
    },
    [portStats]
  );
  useEffect(() => {
    dispatch(setGeographicalType(GEOGRAPHICAL_TYPES.PORT));
  }, []);

  useEffect(() => {
    setSection(parseInt(searchParams.get('section') || 1));
    return () => {
      searchParams.delete('section');
    };
  }, []);

  const { ytd, lastYearPortCalls, prevYearPortCallsYTD } = portStats || {};
  const { portName } = portData || {};
  const isRoRo = segment.id === SEGMENT.RoRo.id;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      {(portStats || loading) && (
        <>
          <Grid item xs={4} id="port-call-ytd">
            <InfoBox
              loading={loading}
              mainValue={ytd || '-'}
              icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
              header={'Port Calls (YTD)'}
              colorSecondary={'#63d5fa'}
              colorMain={'#61efbf'}
              headerColor={'#63d5fa'}
            />
          </Grid>
          <Grid item xs={4} id={'port-call-prev-year-ytd'}>
            <InfoBox
              loading={loading}
              mainValue={prevYearPortCallsYTD || '-'}
              icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
              header={`${moment().subtract(12, 'months').format('yyyy')} Port Calls YTD`}
              colorSecondary={'#57ed80'}
              colorMain={'#57edd1'}
              headerColor={'#57ed80'}
            />
          </Grid>
          <Grid item xs={4} id={'number-of-port-calls'}>
            <InfoBox
              loading={loading}
              mainValue={lastYearPortCalls || '-'}
              icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
              header={`${moment().subtract(12, 'months').format('yyyy')} # Port Calls`}
              colorSecondary={'#66a6ff'}
              colorMain={'#89f7fe'}
              headerColor={'#66a6ff'}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} id={'port-profile-overview'}>
        <Paper>
          <Tabs value={section} onChange={handleSectionChange} variant="scrollable">
            <Tab value={1} label="Overview" />
            <Tab
              value={2}
              sx={{ cursor: portStats ? 'pointer' : 'default' }}
              disableRipple={!portStats}
              label={
                !portStats && !loading ? (
                  <TextWithTooltipIcon
                    color={'text.secondary'}
                    label={'PORT STATISTICS'}
                    tooltipText="Insufficient data to compute emissions"
                  />
                ) : (
                  'PORT STATISTICS'
                )
              }
            />
            <Tab value={3} label="Port Waiting Time" />
            <Tab value={4} label="Operator Statistics" />
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <>
          <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
            <div>
              <PortOverviewSection
                portData={portData}
                setLastPortCalls={setLastPortCalls}
                lastPortCalls={lastPortCalls}
                upcomingPortCalls={upcomingPortCalls}
                maxDraughtLastPortCalls={maxDraughtLastPortCalls}
                loading={loading}
              />
            </div>
          </Slide>
          <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
            <div>
              <PortStatisticsSection portStats={portStats} loading={loading} portName={portName} />
            </div>
          </Slide>
          <Slide direction="right" in={section === 3} mountOnEnter unmountOnExit>
            <div>
              <PortWaitingTimeSection
                assetLevel={'port'}
                portCongestionData={portCongestionData}
                loading={loading}
                portName={portName}
              />
            </div>
          </Slide>
          <Slide direction="right" in={section === 4} mountOnEnter unmountOnExit>
            <div>
              <PortOperatorStatisticsSection
                selectedYear={selectedYear}
                loading={loading}
                portName={portName}
                operatorStats={operatorStats}
              />
            </div>
          </Slide>
        </>
      </Grid>
    </Grid>
  );
}

PortProfileSection.propTypes = {
  loading: PropTypes.bool,
  portData: PropTypes.object,
  lastPortCalls: PropTypes.arrayOf(PropTypes.object),
  portStats: PropTypes.object,
  portCongestionData: PropTypes.object || PropTypes.arrayOf(PropTypes.object),
  setLastPortCalls: PropTypes.func.isRequired,
  operatorStats: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.number,
  upcomingPortCalls: PropTypes.arrayOf(PropTypes.object),
  maxDraughtLastPortCalls: PropTypes.object
};

PortProfileSection.defaultProps = {
  loading: false,
  portData: null,
  lastPortCalls: [],
  portCongestionData: [],
  portStats: null,
  operatorStats: [],
  selectedYear: moment().year(),
  upcomingPortCalls: [],
  maxDraughtLastPortCalls: {}
};

export default PortProfileSection;
