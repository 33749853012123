import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Tab, Tabs } from '@esgian/esgianui';
import { useSearchParams } from 'react-router-dom';
import VesselEmissionsSection from './SubSections/VesselEmissionsSection/VesselEmissionsSection';
import VesselOverviewSection from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection';
import VesselActivitySection from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselActivitySection';
import VesselCiiRatingSection from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselCiiRatingSection';
import { TextWithTooltipIcon } from '@components';

function VesselProfileSection({
  loading,
  vesselsDataByFilters,
  speedHistogramData,
  portCallData,
  latestPositionData,
  setPageCount,
  pageCount,
  setPortCallData,
  nextPortCallData,
  hasEmissionsData,
  hasCiiData
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabSection, setTabSection] = useState(parseInt(searchParams.get('section') || 1));

  const handleSectionChange = (_, val) => {
    if (!hasEmissionsData && val === 3) {
      return;
    }
    if (!hasCiiData && val === 4) {
      return;
    }
    setTabSection(val);
    searchParams.set('section', val);
    setSearchParams(searchParams);
    setPageCount(pageCount - 1);
  };
  const { vesselName, imo } = vesselsDataByFilters?.[0]?.vessel || {};
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Tabs value={tabSection} onChange={handleSectionChange} variant="scrollable">
            <Tab disabled={loading} value={1} label="Overview" id="vessel-overview-tab" />
            <Tab disabled={loading} value={2} label="Activity" id="vessel-activity-tab" />
            <Tab
              sx={{ cursor: hasEmissionsData ? 'pointer' : 'default' }}
              disableRipple={!hasEmissionsData}
              id={'vessel-co2-emissions-tab'}
              value={3}
              label={
                !hasEmissionsData && !loading ? (
                  <TextWithTooltipIcon
                    color={'text.secondary'}
                    label={'CO₂ EMISSIONS'}
                    tooltipText="Insufficient data to compute emissions"
                  />
                ) : (
                  'CO₂ EMISSIONS'
                )
              }
            />
            <Tab
              sx={{ cursor: hasCiiData ? 'pointer' : 'default' }}
              disableRipple={!hasCiiData}
              value={4}
              id="vessel-cii-rating-tab"
              label={
                !hasCiiData && !loading ? (
                  <TextWithTooltipIcon
                    color={'text.secondary'}
                    label={'CII RATING'}
                    tooltipText={'Insufficient data to compute CII'}
                  />
                ) : (
                  'Cii rating'
                )
              }
            />
          </Tabs>
        </Paper>
      </Grid>
      {tabSection === 1 && (
        <VesselOverviewSection
          latestPositionData={latestPositionData}
          vesselsDataByFilters={vesselsDataByFilters}
          portCallData={portCallData}
          nextPortCallData={nextPortCallData}
          loading={loading}
          speedHistogramData={speedHistogramData}
        />
      )}
      {tabSection === 2 && (
        <VesselActivitySection
          setPortCallData={setPortCallData}
          portCallData={portCallData}
          loading={loading}
          vesselName={vesselName}
          vesselImo={imo}
        />
      )}
      {tabSection === 3 && <VesselEmissionsSection vesselName={vesselName} loading={loading} />}
      {tabSection === 4 && <VesselCiiRatingSection vesselName={vesselName} />}
    </Grid>
  );
}

VesselProfileSection.propTypes = {
  vesselsDataByFilters: PropTypes.object,
  latestPositionData: PropTypes.object,
  ownerHistoryData: PropTypes.arrayOf(PropTypes.object),
  portCallData: PropTypes.arrayOf(PropTypes.object),
  operatorHistoryData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  setPageCount: PropTypes.func,
  setPortCallData: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  speedHistogramData: PropTypes.arrayOf(PropTypes.object),
  nextPortCallData: PropTypes.object,
  vesselAvgSpeedData: PropTypes.arrayOf(PropTypes.object),
  hasEmissionsData: PropTypes.bool,
  hasCiiData: PropTypes.bool
};

VesselProfileSection.defaultProps = {
  portCallData: [],
  ownerHistoryData: [],
  operatorHistoryData: null,
  vesselsDataByFilters: null,
  loading: false,
  hasEmissionsData: false,
  hasCiiData: false,
  latestPositionData: null,
  setPageCount: null,
  nextPortCallData: null,
  pageCount: -1,
  vesselAvgSpeedData: null,
  speedHistogramData: []
};

export default VesselProfileSection;
