import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from '../TableWithSummary/TableWithSummary';
import { formatNumberReadable, formatToDecimalPlaces } from '@helpers';

const OverallVoyageExpandedCo2eEmissionTable = ({ overallVoyage, isLoading }) => {
  const columns = [
    { name: 'voyageLeg', label: 'Voyage Leg' },
    { name: 'origin', label: 'Origin' },
    { name: 'destination', label: 'Destination' },
    {
      name: 'estimatedCargoWeightT',
      label: 'Estimated Cargo weight (t)',
      tooltip:
        'Cargo weight is estimated based on the draught of the vessel using the Esgian cargo weight estimation model.'
    },
    {
      name: 'shortestDistanceKm',
      label: 'Distance (km)',
      tooltip:
        "For RORO vessels, 'Distance' corresponds to shortest distance between port pairs estimated by Esgian based on positional data of historical voyages of all vessels in the Esgian database. For MPP vessels, 'Distance' corresponds to actual distance travelled between port pairs based on the positional data of the vessel carrying the cargo."
    },
    {
      name: 'transportActivityTKm',
      label: 'Transport Activity (t-km)',
      tooltip: 'Transport activity is the product of cargo weight (t) and distance (km).'
    },
    {
      name: 'energyProduction',
      label: 'Energy Production',
      tooltip:
        'CO₂e (kg) Emission intensity ( CO₂e (kg) emission per cargo weight carried per kilometer travelled) from energy production (also referred to as Well to Tank (WTT) Emission) of the vessel for the selected voyage',
      customCellValue: (value) => formatToDecimalPlaces(value, 4)
    },
    {
      name: 'operational',
      label: 'Operational',
      tooltip:
        'CO₂e (kg) Emission intensity ( CO₂e (kg) emission per cargo weight carried per kilometer travelled) from vessel operation (also referred to as Tank to Wake ( TTW) Emission) of the vessel for the selected voyage',
      customCellValue: (value) => formatToDecimalPlaces(value, 4)
    },
    {
      name: 'overall',
      label: 'Overall',
      tooltip:
        'CO₂e (kg) Emission intensity ( CO₂e (kg) emission per cargo weight carried per kilometer travelled) from energy production and vessel operation (also referred to as Well to Wake (WTW) Emission) of the vessel for the selected voyage',
      customCellValue: (value) => formatToDecimalPlaces(value, 4)
    }
  ];

  const createDataRow = (d) => ({
    voyageLeg: { value: `#${d.voyageLeg}` },
    origin: { value: d.fromPortName },
    destination: { value: d.toPortName },
    estimatedCargoWeightT: { value: formatToDecimalPlaces(d.cargoWeightTn, 0) },
    shortestDistanceKm: { value: formatToDecimalPlaces(d.distanceKm, 0) },
    transportActivityTKm: { value: formatNumberReadable(d.transportActivity) },
    energyProduction: { value: d.emissionIntensityEneryProduction },
    operational: { value: d.emissionIntensityOperational },
    overall: { value: d.emissionIntensityOverall }
  });

  const data = overallVoyage?.voyageLegEmissionIntensities?.map(createDataRow) || [];

  const totalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    estimatedCargoWeightT: { value: '-' },
    shortestDistanceKm: { value: formatToDecimalPlaces(overallVoyage?.totalDistanceKm, 0) },
    transportActivityTKm: { value: '-' },
    energyProduction: { value: null },
    operational: { value: null },
    overall: { value: null }
  };

  return (
    <TableWithSummary
      title="Overall Voyage Emission intensity"
      columns={columns}
      data={[...data, totalRow]}
      isLoading={isLoading}
      id="overall-voyage-co2e-emission-table"
    />
  );
};

OverallVoyageExpandedCo2eEmissionTable.propTypes = {
  overallVoyage: PropTypes.object,
  isLoading: PropTypes.bool
};

export default OverallVoyageExpandedCo2eEmissionTable;
