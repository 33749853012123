import React from 'react';
import { Link, Typography } from '@esgian/esgianui';

//-------------------------- OPERATOR PROFILE --------------------------
export const OPERATOR_PROFILE_FLEET_CII_RATING_TEXT = `Number of vessels of the fleet with each CII rating based on estimated CO₂ emission. 
Completed years have ratings based on the emission for the corresponding year. 
Rating for the current year and upcoming years are ratings based on the rating criteria for the 
corresponding year assuming the fleet operation is same as the latest completed year.`;

//-------------------------- DRAUGHT ON ARRIVAL/DEPARTURE  --------------------------
export const DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT = `Draught Reported / Max Draught (%)`;
export const DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT = `Draught Reported / Max Draught (%) at POD`;

//-------------------------- VESSEL PROFILE --------------------------

//-------------------------- REGION PROFILE --------------------------

//-------------------------- COUNTRY PROFILE --------------------------

//-------------------------- PORT PROFILE --------------------------

//-------------------------- OWNER PROFILE --------------------------

//-------------------------- GENERAL --------------------------
export const LAST_PORT_CALLS_RECORDS_TEXT = `Displaying only the most recent 250 port calls. The complete list is accessible in the download button.`;
export const LESS_THAN_250_RECORDS = `Total number of port calls:`;
export const GLOBAL_AVERAGE_PORT_TIME_TEXT = `The port time is defined as the time when a vessel is within a terminal geofence and whose speed is less than 1 kn. In the case of vessel visiting multiple terminal geofences during the port event, the port time is taken as the time from entering the first geofence, to leaving the final geofence, where speed is less than 1 kn.`;
//-------------------------- CATEGORY FILTERS --------------------------

export const COM_CAT_TYPE_ALL_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      This filter displays all vessels covered in the MPP Segment in Esgian Shipping.
    </Typography>
  </React.Fragment>
);

export const COM_CAT_TYPE_DEEP_SEA_CORE_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      This is the primary MPP segment with vessels having at least 100 mt of combined lifting
      capacity, at least 2.500 dwt cargo carrying capacity and max 30 years of age.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      The vessels falling into this category are regularly mapped to a commercial operator by
      Toepfer Transport GmbH.
    </Typography>
  </React.Fragment>
);

export const COM_CAT_TYPE_DEEP_SEA_EXT_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      This segment includes all vessels/operators in the “Deep-sea Core” category, but also vessels
      by operators in the Core category not meeting all selection criteria enabling a more complete
      view at the operator level.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      In addition, key players such as SagaWelco and G2 Ocean can be found in this segment. The
      vessels falling into this category are regularly mapped to a commercial operator by Toepfer
      Transport GmbH.
    </Typography>
  </React.Fragment>
);
export const COM_CAT_TYPE_SHORT_SEA_EUR_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      Depending on definition, the EU Short-sea MPP fleet counts some 2.000 - 3.000 ships.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      The Esgian suite seeks to cover the main 20 + operators and their fleet, but is continuously
      expanding.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      The vessels falling into this category are regularly mapped to a commercial operator by
      Toepfer Transport GmbH.
    </Typography>
  </React.Fragment>
);

export const COM_CAT_TYPE_OTHER_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      Vessels and operators falling into the “other” category are not deemed as primary MPP tonnage,
      but more general cargo vessels/operators which may be considered tier two players in this
      market.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      The vessels falling into this category are NOT mapped by Toepfer and done on a “best effort
      basis”.
    </Typography>
  </React.Fragment>
);

export const OPR_TYPE_BOTH_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      This filter shows all RoRo vessels and operators tracked by Esgian and as such combines both
      Deep-sea and Short-sea operators.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      From the outset, our threshold is vessels with the CEU capacity {'>'} 1.800, but for Short-sea
      this is lower to capture the entire fleet of the operators covered in our software.
    </Typography>
  </React.Fragment>
);

export const OPR_TYPE_SHORT_SEA_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      This filter displays operators and their fleet who is primarily engaged in intracontinental
      trading.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      Like for Deep-Sea, if the majority of the activity is Short-Sea, the operator’s activity will
      count towards this segment. One example here is Neptune Lines.
    </Typography>
  </React.Fragment>
);
export const OPR_TYPE_DEEP_SEA_TEXT = (
  <React.Fragment>
    <Typography variant={'caption'}>
      This filter displays operators and their fleet which is primarily engaged in intercontinental
      trading.
    </Typography>
    <br />
    <Typography variant={'caption'}>
      If a carrier has most of its activity between regions, their activity will count towards the
      Deep-sea segment. One example here is Toyofuji.
    </Typography>
  </React.Fragment>
);

//-------------------------- VOYAGE ANALYTICS --------------------------

export const ESTIMATED_AVG_EU_ETS_COST_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      Estimated EU ETS cost based on Avg. cargo weight of the entire voyage
    </Typography>
  </React.Fragment>
);

export const EU_ALLOWANCE_FOR_MY_CARGO = (
  <React.Fragment>
    <Typography variant={'body2'}>
      EU Allowances (EUAs) are a type of carbon allowance that allows companies covered by the EU
      ETS to emit a certain amount of CO₂e.
    </Typography>
  </React.Fragment>
);

export const OVERALL_VOYAGE_PHASE_IN_FACTOR = (
  <React.Fragment>
    <Typography variant={'body2'}>
      To ensure a smooth transition, shipping companies only have to surrender allowances for a
      portion of their emissions during an initial phase-in period:
    </Typography>
    <Typography variant={'body2'}>- 2025: for 40% of their emissions reported in 2024</Typography>
    <Typography variant={'body2'}>- 2026: for 70% of their emissions reported in 2025</Typography>
    <Typography variant={'body2'}>- 2027 onwards: for 100% of their reported emissions</Typography>
  </React.Fragment>
);

export const OVERALL_VOYAGE_EU_ALLOWANCES = (
  <React.Fragment>
    <Typography variant={'body2'}>EU ETS covers</Typography>
    <Typography variant={'body2'}>
      -50% of emissions from voyages starting or ending outside of the EU (allowing the third
      country to decide on appropriate action for the remaining share of emissions);
    </Typography>
    <Typography variant={'body2'}>
      -100% of emissions that occur between two EU ports and when ships are within EU ports.
    </Typography>
  </React.Fragment>
);
export const ETS_COST_PER_TONNE = (
  <React.Fragment>
    <Typography variant={'body2'} style={{ whiteSpace: 'wrap' }}>
      EU-ETS Allowance price. Esgian assumes the price as the price corresponding to the day when
      the voyage ended. User is able to edit this value and get recalculated results.
    </Typography>
    <Typography variant={'body2'} style={{ whiteSpace: 'nowrap' }}>
      The values are referred from
      <Link href="https://www.ice.com/products/197/EUA-Futures/data?marketId=7075802&span=1">
        here
      </Link>
    </Typography>
  </React.Fragment>
);

//-------------------------- OPERATORS OVERVIEW --------------------------
export const IN_ASSOCIATION_WITH_HESNES_SHIPPING_AS_TOOLTIP = (
  <React.Fragment>
    <Typography variant={'caption'}>
      All vessels with a CEU capacity of 1,800 and above are dynamically assigned to a commercial
      operator by Hesnes Shipping. Smaller vessels are not covered by Hesnes and are updated by
      Esgian less frequently based on operator feedback or market updates.
    </Typography>
  </React.Fragment>
);
