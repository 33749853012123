import React, { useMemo } from 'react';
import { Box, Button, Paper, Typography } from '@esgian/esgianui';
import VoyageSummaryMapOptions from './VoyageSummaryMapOptions';
import PropTypes from 'prop-types';
import VoyageSummaryDateSlider from './VoyageSummaryDateSlider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';
import { updateVoyageProfileFilters } from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';

const VoyageSummaryFilter = ({
  voyageTransitDetails,
  voyagePortCalls,
  isOngoingVoyage,
  onShowVoyageDetails
}) => {
  const [searchParams] = useSearchParams();
  const { themeMode } = useSelector(getGeneralSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const exitFullScreenMode = () => {
    dispatch(updateVoyageProfileFilters({ mapOptions: { fullscreen: false } }));
    searchParams.set('fullscreen', false);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const endDate = useMemo(
    () => (isOngoingVoyage ? new Date().toISOString() : voyageTransitDetails.endDate),
    [voyageTransitDetails, isOngoingVoyage]
  );

  return (
    <Paper
      style={{
        position: 'absolute',
        zIndex: 1,
        left: '16px',
        top: '16px',
        right: '16px',
        backgroundColor: themeMode ? '#191919E5' : '#FFFFFFE5'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          px: 2,
          py: 1
        }}>
        {!isOngoingVoyage && (
          <Button
            variant="contained"
            onClick={exitFullScreenMode}
            id="exit-fullscreen-button"
            startIcon={
              <img src="/assets/images/full-screen-exit-icon.png" alt="Fullscreen Exit" />
            }>
            <Typography color="black">Exit</Typography>
          </Button>
        )}
        <VoyageSummaryDateSlider
          startDate={voyageTransitDetails?.startDate}
          endDate={endDate}
          voyagePortCalls={voyagePortCalls}
          isOngoingVoyage={isOngoingVoyage}
        />
        <VoyageSummaryMapOptions isOngoingVoyage={isOngoingVoyage} />
        {isOngoingVoyage && (
          <Button
            aria-controls="show-voyage-details"
            aria-haspopup="true"
            onClick={onShowVoyageDetails}
            id="show-voyage-details-button"
            variant="outlined"
            sx={{
              borderRadius: 1
            }}>
            <Typography color="primary">Voyage Details</Typography>
          </Button>
        )}
      </Box>
    </Paper>
  );
};

VoyageSummaryFilter.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired,
  voyagePortCalls: PropTypes.object.isRequired,
  isOngoingVoyage: PropTypes.bool,
  onShowVoyageDetails: PropTypes.func
};

export default VoyageSummaryFilter;
