import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import { useNavigate, useParams } from 'react-router-dom';
import RegionSection from '@components/Sections/ShipAnalytics/RegionSection';
import {
  getRegionTopPorts,
  getRegionPortStats,
  getRegionPorts,
  getRegionUpcomingPortCalls,
  getPortCongestion,
  getLatestPortCalls
} from '@api';

import { DATE_FORMAT, DEFAULT_NUM_PORT_CALLS, GEOGRAPHICAL_TYPES } from '@constants';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useSegment, useStaticLookupQueries } from '@hooks';

function RegionProfilePage() {
  const [regionName, setRegionName] = useState(null);
  const [regionPortsData, setRegionPortsData] = useState(null);
  const [regionPortCallsData, setRegionPortCallsData] = useState(null);
  const [regionUpcomingPortCallsData, setRegionUpcomingPortCallsData] = useState(null);
  const [regionPortStatsData, setRegionPortStatsData] = useState(null);
  const [regionTopPortsData, setRegionTopPortsData] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [portCongestionData, setPortCongestionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    lookupRegionsQuery: { data: lookupRegions }
  } = useStaticLookupQueries();
  const operatorType = useSelector(getOperatorType);
  const { isRoRo, segment, segmentMinDate, uriExt } = useSegment();
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  const params = useParams();

  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (!lookupRegions.length) return;
    let region = lookupRegions.find(({ regionId }) => regionId === parseInt(params.regionId));
    setRegionName(region.name);
  }, [lookupRegions]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;
    const getRegionData = async (regionId) => {
      try {
        setLoading(true);
        await getRegionPorts(
          {
            RegionId: regionId,
            SegmentTypeId: segment.id,
            commercialCategoryId: commercialCategoryType
          },
          signal
        ).then((result) => {
          setRegionPortsData(result);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getRegionData(params.regionId);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment, commercialCategoryType]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getParamData = async (regionId) => {
      try {
        await Promise.all([
          getLatestPortCalls(
            {
              geographicalType: GEOGRAPHICAL_TYPES.REGION,
              segmentTypeId: segment.id,
              operatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType,
              numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
              geoIds: [regionId]
            },
            signal
          ).then((result) => {
            setRegionPortCallsData(result);
          }),
          getRegionUpcomingPortCalls(
            {
              regionId: regionId,
              NumberOfIncomingEntries: DEFAULT_NUM_PORT_CALLS,
              segmentTypeId: segment.id,
              operatorTypeId: operatorType,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setRegionUpcomingPortCallsData(result);
          }),
          getRegionPortStats(
            {
              RegionId: regionId,
              OperatorTypeId: operatorType,
              segmentTypeID: segment.id,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setRegionPortStatsData(result);
          }),
          getRegionTopPorts(
            {
              RegionId: regionId,
              NumberOfPorts: 20,
              NumberOfOperators: 20,
              Year: selectedYear,
              OperatorTypeId: operatorType,
              SegmentTypeId: segment.id,
              commercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            setRegionTopPortsData(result.topPortsYTD);
          }),
          getPortCongestion(
            {
              StartDate: moment(segmentMinDate).format(DATE_FORMAT),
              EndDate: moment().format(DATE_FORMAT),
              GeoType: GEOGRAPHICAL_TYPES.REGION,
              commercialCategoryId: commercialCategoryType,
              OperatorTypeId: operatorType,
              SegmentTypeId: segment.id,
              GeoId: regionId
            },
            signal
          ).then((result) => {
            setPortCongestionData(result);
          })
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getParamData(params.regionId);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment, operatorType, selectedYear, commercialCategoryType]);

  const handleSelectYear = useCallback(({ target }) => {
    setSelectedYear(target.value);
  }, []);

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(-2)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          loading={!regionName}
          title={`${regionName}`}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange(`/${uriExt}/dashboard`)}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                onClick={() => routeChange(`/${uriExt}/commercial-analytics`)}>
                Commercial Analytics
              </Link>
              <Typography color="primary">Region Profile</Typography>
            </Breadcrumbs>
          }
        />
      </Grid>

      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>

      <Grid item xs={12}>
        <RegionSection
          setRegionPortCallsData={setRegionPortCallsData}
          loading={loading}
          regionId={parseInt(params.regionId)}
          regionName={regionName}
          regionPortCallsData={regionPortCallsData}
          regionPortsData={regionPortsData}
          regionPortStatsData={regionPortStatsData}
          regionTopPortsData={regionTopPortsData}
          selectedYear={selectedYear}
          handleSelectYear={handleSelectYear}
          regionUpcomingPortCallsData={regionUpcomingPortCallsData}
          portCongestionData={portCongestionData}
        />
      </Grid>
    </Grid>
  );
}

RegionProfilePage.propTypes = {};

RegionProfilePage.defaultProps = {};

export default RegionProfilePage;
