import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Grid, Paper, Tab, Tabs, InfoBox, ShipsIcon } from '@esgian/esgianui';
import HistoricalActivity from '@components/Sections/ShipAnalytics/RegionSection/HistoricalActivity/HistoricalActivity';
import moment from 'moment';
import { YtdPortCallStatsTable } from '@components/Tables';
import {
  RegionActivitySection,
  RegionOverviewSection,
  RegionPortStatisticsSection
} from '@components/Sections/ShipAnalytics/RegionSection/SubSections';
import PortWaitingTimeSection from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection';
import { useSegment } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';

import { cleanUpCurrentParams } from '@helpers';
import {
  getRegionProfileSection,
  setRegionProfileSection
} from '@store/features/filters/RegionProfilePage/RegionProfileReducer';
import { GEOGRAPHICAL_TYPES } from '@constants';
import { setGeographicalType } from '@store/features';

function RegionSection({
  loading,
  regionId,
  regionName,
  regionPortCallsData,
  regionPortsData,
  regionPortStatsData,
  regionTopPortsData,
  handleSelectYear,
  selectedYear,
  setRegionPortCallsData,
  regionUpcomingPortCallsData,
  portCongestionData
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { ytd, lastYearPortCalls, prevYearPortCallsYTD } = regionPortStatsData || {};
  const { isRoRo } = useSegment();
  const dispatch = useDispatch();
  const section = useSelector(getRegionProfileSection);
  const isProd = process.env.CURRENT_ENV === 'prod';
  const handleSectionChange = (_, val) => {
    const params = cleanUpCurrentParams(searchParams);
    dispatch(setRegionProfileSection(val));
    params.set('section', val);
    setSearchParams(params);
  };
  useEffect(() => {
    dispatch(setGeographicalType(GEOGRAPHICAL_TYPES.REGION));
  }, []);

  useEffect(() => {
    if (searchParams.has('section')) {
      dispatch(setRegionProfileSection(parseInt(searchParams.get('section'))));
    } else {
      searchParams.set('section', section);
      setSearchParams(searchParams);
    }
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <InfoBox
          loading={loading}
          mainValue={ytd}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          header={'Port Calls (YTD)'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
        />
      </Grid>
      <Grid item xs={4}>
        <InfoBox
          loading={loading}
          mainValue={prevYearPortCallsYTD}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          header={`${moment().subtract(12, 'months').format('yyyy')} Port Calls YTD`}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
        />
      </Grid>
      <Grid item xs={4}>
        <InfoBox
          loading={loading}
          mainValue={lastYearPortCalls}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          header={`${moment().subtract(12, 'months').format('yyyy')} # Port Calls`}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <Tabs
                value={section}
                onChange={handleSectionChange}
                variant="scrollable"
                aria-label="scrollable prevent tabs example">
                <Tab value={1} label="Overview" />
                <Tab value={2} label="Port Statistics" />
                <Tab value={3} label="Region Activity" />
                <Tab value={4} label="Historical Activity" />
                <Tab value={5} label="Top Ports in Region" />
                <Tab value={6} label="Port Waiting Time" />
              </Tabs>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {section === 1 && (
            <RegionOverviewSection
              setRegionPortCallsData={setRegionPortCallsData}
              regionPortCallsData={regionPortCallsData}
              loading={loading}
              regionName={regionName}
              geoId={regionId}
              regionPortsData={regionPortsData}
              regionUpcomingPortCallsData={regionUpcomingPortCallsData}
            />
          )}
          {section === 2 && (
            <RegionPortStatisticsSection
              loading={loading}
              regionName={regionName}
              regionPortStatsData={regionPortStatsData}
            />
          )}
          {section === 3 && <RegionActivitySection regionId={regionId} />}
          {section === 4 && <HistoricalActivity regionId={regionId} regionName={regionName} />}
          {section === 5 && (
            <Grid item xs={12}>
              <YtdPortCallStatsTable
                topPortsData={regionTopPortsData}
                handleSelectYear={handleSelectYear}
                selectedYear={selectedYear}
                subTableHeader={`Operators YTD ${selectedYear}`}
              />
            </Grid>
          )}
          {section === 6 && (
            <Grid item xs={12}>
              <PortWaitingTimeSection
                assetLevel={'port'}
                loading={loading}
                name={regionName}
                portCongestionData={portCongestionData}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

RegionSection.propTypes = {
  regionId: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  regionPortCallsData: PropTypes.arrayOf(PropTypes.object),
  regionPortsData: PropTypes.arrayOf(PropTypes.object),
  regionPortStatsData: PropTypes.object,
  portCongestionData: PropTypes.object,
  regionName: PropTypes.string,
  regionTopPortsData: PropTypes.arrayOf(PropTypes.object),
  handleSelectYear: PropTypes.func.isRequired,
  setRegionPortCallsData: PropTypes.func.isRequired,
  selectedYear: PropTypes.number,
  regionUpcomingPortCallsData: PropTypes.arrayOf(PropTypes.object)
};

RegionSection.defaultProps = {
  regionPortCallsData: [],
  regionTopPortsData: [],
  regionPortsData: [],
  portCongestionData: {},
  regionPortStatsData: null,
  regionName: '',
  loading: false,
  selectedYear: moment().year(),
  regionUpcomingPortCallsData: []
};

export default RegionSection;
