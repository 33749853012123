import React, { useMemo, useState } from 'react';
import ActivityMap from '@components/Sections/ShipAnalytics/ActivityMapSection/ActivityMap';
import { Grid, Paper, Stack } from '@esgian/esgianui';
import moment from 'moment';
import SectionFilters from '@components/Sections/ShipAnalytics/ActivityMapSection/SectionFilters';
import {
  getActivityMapFilters,
  setSelectedSlideDate
} from '@store/features/filters/CommercialAnalyticsPage/ActivityMapSlice/ActivityMapSlice';
import { useDispatch, useSelector } from 'react-redux';
import VesselsPerRegionTable from '@components/Sections/ShipAnalytics/ActivityMapSection/VesselsPerRegionTable';
import { getDateFormat } from '@store/features';
import { getComAnalyticsSection } from '@store/features/filters/CommercialAnalyticsPage/CommercialAnalyticsReducer';

function ActivityMapSection() {
  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState(null);
  const dispatch = useDispatch();
  const dateFormat = useSelector(getDateFormat);
  const { selectedDate, selectedSlideDate } = useSelector(getActivityMapFilters);
  const section = useSelector(getComAnalyticsSection);

  const displayData = useMemo(() => {
    if (!activityData) return null;
    const { regionVisitInfo } = activityData;
    let currentDate = moment(selectedDate, dateFormat).subtract(30 - selectedSlideDate, 'days');
    return regionVisitInfo.find(({ date }) => moment(date).isSame(currentDate, 'day'));
  }, [activityData, selectedSlideDate, selectedDate]);
  return (
    <Stack spacing={2}>
      <Paper>
        <Grid container spacing={2} sx={{ ml: 0 }}>
          <SectionFilters
            setActivityData={setActivityData}
            setLoading={setLoading}
            loading={loading}
            isActive={true}
          />
          <Grid item xs={9} alignSelf={'center'} sx={{ paddingLeft: '0px !important' }}>
            <ActivityMap
              loadingData={loading}
              selectedSlideDate={selectedSlideDate}
              setSelectedSlideDate={(val) => {
                dispatch(setSelectedSlideDate(val));
              }}
              selectedDate={selectedDate}
              activityData={displayData}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ maxWidth: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {section === 1 && ( // Required to remove lag when switching sections.
              <VesselsPerRegionTable
                selectedDate={selectedDate}
                loading={loading}
                activityData={displayData}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
}

ActivityMapSection.propTypes = {};

ActivityMapSection.defaultProps = {};

export default ActivityMapSection;
