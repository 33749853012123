import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { fetchLookupRegions, getOperatorStats } from '@api';
import OperatorsOverviewSection from '@components/Sections/ShipAnalytics/OperatorsOverviewSection';
import { REGION_TYPES, SEGMENT } from '@constants';
import { useSegment } from '@hooks';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';
import { useQuery } from '@tanstack/react-query';

function OperatorsOverviewPage() {
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);
  const { segment, isRoRo } = useSegment();
  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  const operatorsDataQuery = useQuery({
    queryKey: ['operatorOverview', segment, operatorType, commercialCategoryType],
    placeholderData: null,
    queryFn: ({ signal }) => {
      window.scrollTo(0, 0);
      let body = { SegmentTypeId: segment.id, minFleetSize: 1 };
      if (isRoRo) {
        body.OperatorTypeId = operatorType;
      } else {
        body.CommercialCategoryId = commercialCategoryType;
      }
      return getOperatorStats(body, signal)
        .then((resp) => resp)
        .catch(() => []);
    }
  });

  let uriExt = segment?.id === SEGMENT.RoRo.id ? 'roro' : 'mpp';
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          id={'lbl-operator-overview'}
          title={'Operators Overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="home-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange(`/${uriExt}/commercial-analytics`)}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange(`/${uriExt}/commercial-analytics`)}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="operator-overview">
                Operators Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>

      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      <Grid item xs={12}>
        <OperatorsOverviewSection
          operatorDetails={operatorsDataQuery.data}
          loading={operatorsDataQuery.isFetching}
        />
      </Grid>
    </Grid>
  );
}

OperatorsOverviewPage.propTypes = {};

OperatorsOverviewPage.defaultProps = {};

export default OperatorsOverviewPage;
